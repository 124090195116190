import {getProblemCategory} from "../dataStore/PSExecutionDataStore";

export const processExceptionResolutionResponse= (response) => {
    let exceptionResolutionResult = {}
    if(response.success) {
        exceptionResolutionResult = {successCount: 1, totalPackages: 1}
    } else {
        exceptionResolutionResult = {successCount: 0, totalPackages: 1}
    }
    return exceptionResolutionResult

}

export const getErrorMessageId = () => {
    if(getProblemCategory() === "TRANSPORTER_DISCHARGE") {
        return "discharge_driver_failure"
    } else {
        return "override_qr_code_failure"
    }
}