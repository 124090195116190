import {OPEN, ScrubCategories} from "../constants/constants";
import {getExceptionShipmentSummaryWithoutShipDetails} from "../../../network/GetExceptionShipmentSummaryWithoutShipDetailsAPI";
import {getExceptionShipmentSummary} from "../../../network/GetExceptionShipmentSummaryAPI";
import {scrubPackage} from "../../../network/ScrubPackageAPI";

export function getStartTimeForScrub() {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()-1, 0, 0,0);
    return startDate.toString()
}

export const fetchExceptionShipmentSummary = (category, dispatcher, errorNotifier) => {
    getExceptionShipmentSummary({
        category: ScrubCategories[category],
        startDate: getStartTimeForScrub(),
        endDate: new Date().toString(),
    }, dispatcher, errorNotifier)
}

export function fetchExceptionShipmentSummaryWithoutShipDetails(dispatcher, errorNotifier) {
    const categories =  ScrubCategories.ALL
    getExceptionShipmentSummaryWithoutShipDetails({
        category: categories,
        startDate: getStartTimeForScrub(),
        endDate: new Date().toString()
    }, dispatcher, errorNotifier)
}

export function fetchScrubPackage(trackingId, selectedCategory, dispatcher, errorNotifier) {
    scrubPackage({
        scannableId: trackingId,
        problemCategory: selectedCategory
    },dispatcher, errorNotifier)
}

export function fetchScrubExceptionToCount(scrubSummaryResponse) {
    let scrubSummaryForCategory = scrubSummaryResponse.exceptionShipmentSummaryForCategory
    const scrubSummary = []
    Object.keys(ScrubCategories).forEach((category) => {
        let scrubExceptionCount = 0
        ScrubCategories[category].forEach((bucket) => {
            scrubExceptionCount += scrubSummaryForCategory[bucket][OPEN].length
        })
        scrubSummary.push({queueName: category, exceptionCount: scrubExceptionCount })
    })
    return scrubSummary.filter(({exceptionCount = 0}) => exceptionCount !== 0)
}

export const fetchScrubDetailCategoryMap = (scrubCategory, {exceptionShipmentSummaryForCategory}) => {
    const exceptionDetails = {}
    const scrubBuckets = ScrubCategories[scrubCategory]
    scrubBuckets
        .filter(key => exceptionShipmentSummaryForCategory[key])
        .forEach((key) => {
        exceptionShipmentSummaryForCategory[key][OPEN].forEach((packageItem) => {
            exceptionDetails[packageItem.scannableId] = packageItem
        })
    })
    return exceptionDetails
}