import React from 'react';
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import moment from "moment";
import Column from "@amzn/meridian/column";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import {getRegion} from "../../config/NetConf";
import {Constants} from "@amzn/dolphin-web-framework";
import {PHARMACY_SHIPMENT_CATEGORY} from "../../constants/Constants"
import Row from "@amzn/meridian/row";

export const ShipmentDetail = ({shipmentDetail}) => {
    const showTransporterPersonalDetails = getRegion() !== Constants.Region.REGION_EU;
    return (
        <Column spacing="small" width="100%" spacingInset="medium">
            <Box type="outline" spacingInset="small">
                {shipmentDetail.scannableId &&
                <div tabIndex={0}>
                    <Text type="h100">{shipmentDetail.scannableId}</Text>
                    <Text><FormattedMessage id="ps_scannable_id"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.transporterName &&
                <div tabIndex={0}>
                    {showTransporterPersonalDetails ?
                        <Text type="h100">{shipmentDetail.transporterName}</Text> :
                        <Text type="h100">{shipmentDetail.transporterId}</Text>
                    }
                    {shipmentDetail.transporterCompany &&
                    <Text type="h100">({shipmentDetail.transporterCompany})</Text>
                    }
                    <Text><FormattedMessage id="driver"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.route && shipmentDetail.route.code &&
                <div tabIndex={0}>
                    <Text type="h100">{shipmentDetail.route.code}</Text>
                    <Text><FormattedMessage id="route"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.address && shipmentDetail.address.addressType &&
                <div tabIndex={0}>
                    <Text type="h100">{shipmentDetail.address.addressType}</Text>
                    <Text><FormattedMessage id="address_type"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.packageState &&
                <div tabIndex={0}>
                    <Text type="h100">{shipmentDetail.packageState}</Text>
                    <Text><FormattedMessage id="package_state"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.nextDestinationType &&
                <div tabIndex={0}>
                    <Text type="h100">{shipmentDetail.nextDestinationType}</Text>
                    <Text><FormattedMessage id="ps_package_destination"/></Text>
                    <br/>
                </div>
                }


                {shipmentDetail.routeZone &&
                <div tabIndex={0}>
                    <Text type="h100">{shipmentDetail.routeZone}</Text>
                    <Text><FormattedMessage id="route_zone"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.sortZone &&
                <div tabIndex={0}>
                    <Text type="h100">{shipmentDetail.sortZone}</Text>
                    <Text><FormattedMessage id="ps_sort_zone"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.location &&
                <div tabIndex={0}>
                    <Text type="h100">{shipmentDetail.location}</Text>
                    <Text><FormattedMessage id="ps_location"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.manifestRouteCode &&
                <div tabIndex={0}>
                    <Text type="h100">{shipmentDetail.manifestRouteCode}</Text>
                    <Text><FormattedMessage id="manifest_route_code"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.promiseDate &&
                <div tabIndex={0}>
                    <Text type="h100">{moment(shipmentDetail.promiseDate).format("DD-MM-YYYY")}</Text>
                    <Text><FormattedMessage id="promise_date"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.shipmentCategory && shipmentDetail.shipmentCategory === PHARMACY_SHIPMENT_CATEGORY &&
                <div>
                    <Text type="h100">{shipmentDetail.shipmentCategory}</Text>
                    <Text><FormattedMessage id="shipment_category"/></Text>
                    <br/>
                </div>
                }

                {shipmentDetail.salColor &&
                <div>
                    <Row><Box backgroundColor={shipmentDetail.salColor} spacingInset={"medium"} type={"outline"}></Box></Row>
                    <Text><FormattedMessage id="shipment_sal_color"/></Text>
                    <br/>
                </div>
                }
            </Box>
        </Column>
    );
};
ShipmentDetail.propTypes = {
    shipmentDetail: PropTypes.object
};