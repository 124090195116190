import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNPSWClient} from "../../base/ClientInterface";

export function UpdateExceptionTransporterAPI(data, nextAction, errorAction, dispatcher, overrideReason) {
    let startTime = Date.now();
    getNPSWClient().updateExceptionTransporter(data, overrideReason)
        .then(({success, data}) => {
            if (success) {
                dispatcher({
                    type: nextAction,
                    data: data,
                    dispatcher: dispatcher
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UpdateExceptionShipmentsForMultipleProblemCategory, startTime);
            } else {
                dispatcher({
                    type: errorAction,
                    dispatcher: dispatcher
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UpdateExceptionShipmentsForMultipleProblemCategory, startTime, true);
            }
        })
}