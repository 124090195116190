import React, {useEffect, useCallback, useContext, useReducer} from 'react';
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import {useHistory, withRouter} from "react-router-dom";
import {NOTIFICATION_TYPE, PROBLEM_CATEGORY_STRING_PREFIX} from "../../constants/Constants";
import PropTypes from 'prop-types';
import {
    getNetworkMissortHeading, getNetworkMissortGuidanceMessage
} from "../../helper/SuccessScreenHelper";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    getexceptionResolutionResult, getProblemCategory,
    getScannedScannableIds
} from "../../dataStore/PSExecutionDataStore";
import {GET_PACKAGE_LABEL_ACTION_TYPE, GetPackageLabelReducer} from "../../reducers/GetPackageLabelReducer";
import InProgress from "../common/InProgress";
import NotificationMessage from "../common/NotificationMessage";
import {ProblemActionReducerActionType} from "../../reducers/ProblemActionReducer";
import Printer from "../../print/Printer";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import {
    clearBackPressedEventListener,
    interceptBackButton,
    interceptBackButtonStop,
    setBackPressedEventListener
} from "../backPressedView/BackButton";
import {SoundManager} from "../../audio/SoundManager";
import {getPackageLabelType} from "../../print/PrintHelper";
import {AppContext} from "../backPressedView/AppContext";
import {setPrimaryOnClickListener} from "../common/TopNavigation";
import Row from "@amzn/meridian/row";
import {DetailsCardView} from "@amzn/dolphin-uiweb-framework";
import {ROUTE_PATH} from "../../router/routing";
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";

const NetworkMissortSuccessScreen = (props) => {
    const [{isLoading, notificationType, notificationMessageId}, dispatch] = useReducer(GetPackageLabelReducer, {isLoading: false});
    const result = getexceptionResolutionResult();
    const scannableId = getScannedScannableIds()[0];
    const additionalInformation = result.additionalInformation[scannableId];
    const planDirection = additionalInformation.shipmentPlan.planDirection;
    const isPrintLabelRequired = additionalInformation.shipmentPlan.needsNewShippingLabel;
    const heading = getNetworkMissortHeading(planDirection, isPrintLabelRequired)
    const guidanceMessage = getNetworkMissortGuidanceMessage(planDirection)

    const history = useHistory();

    const handleSolveMoreProblemsAndBackPressEvent = () => {
        Printer.closePrinter();
        history.push(ROUTE_PATH.HOME)
    };

    const {state: {
        onBackPressed,
        backOptions,
    } = {}, appActions} = useContext(AppContext);

    const onBackPressedEvent = useCallback(() => {
        if (backOptions.open) {
            appActions.closeBackDrop();
        }  else {
            appActions.openBackDrop();
        }
    }, [onBackPressed, backOptions]);


    useEffect(() => {
        SoundManager.playSuccessSound();

        if ( disableBackButton()) {
            setBackPressedEventListener(onBackPressedEvent);
            interceptBackButton()
            setPrimaryOnClickListener(() => handlePrintCommand(history, dispatch));
        }

        return () => {
            if(isPrintLabelRequired){Printer.closePrinter()}
            interceptBackButtonStop();
            clearBackPressedEventListener();
        }

    },[]);

    useEffect(()=> {
        if(notificationType === NOTIFICATION_TYPE.SUCCESS) {
            history.push("/ps/printSuccessScreen");
        }
    }, [notificationType]);

    const disableBackButton = () => {
        return  isPrintLabelRequired;
    };

    const handlePrintCommand = (history, dispatch) => {
        Printer.getConnectionStatus().then((connectionStatus) => {
            if (connectionStatus === "true") {
                dispatch({
                    type: GET_PACKAGE_LABEL_ACTION_TYPE.FETCH_PACKAGE_LABEL,
                    data: {
                        scannableId: getScannedScannableIds()[0],
                        labelType: getPackageLabelType()
                    },
                    dispatcher: dispatch
                });
            } else {
                history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER);
            }
        }).catch(()=>{
            history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER);
        });

    };

    const getPackageData = (title, description) => {
        return [{title: title, description: description}]
    }

    return (
        <Column height="60%" heights={["fill","fit"]}>
            <Column spacingInset="medium">
                {isLoading && <InProgress/>}
                {notificationType && <NotificationMessage dispatch={dispatch} actionType={ProblemActionReducerActionType.CLOSE_NOTIFICATION}
                                                          type={notificationType} messageId={notificationMessageId}/>}
                    <br/>
                        <Row alignmentHorizontal="center" alignmentVertical="top" height="3%">
                            <Text type="h300"><FormattedMessage id={heading}/></Text>
                        </Row>
                        <div>
                            <hr width="80%" color="#9e9493"></hr>
                            <Row alignmentHorizontal="center" alignmentVertical="top" height="5%">
                                <Text type="b200" alignment="center"><FormattedMessage id={guidanceMessage}/></Text>
                            </Row>
                        </div>
                    <br/>

                <DetailsCardView snapshots={getPackageData(scannableId, "Package")}></DetailsCardView>


            </Column>
            <div className="spaceBlockerRTO"/>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
            {isPrintLabelRequired &&
                <Button type="secondary" size="large" onClick={() => handlePrintCommand(props.history,
                    dispatch)}><FormattedMessage id="ps_print_label"/></Button>}
            {!isPrintLabelRequired &&
                <Button size="large" onClick={() => handleSolveMoreProblemsAndBackPressEvent()}>
                    <FormattedMessage id="solve_more_problems"/>
                </Button>

            }
            </Column>
            <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
        </Column>
);
};


NetworkMissortSuccessScreen.propTypes = {
    primaryTitle: PropTypes.string,
    imageUrl: PropTypes.string,
    isPrintLabelSupported: PropTypes.bool,
    isSolveMoreProblemsVisible: PropTypes.bool,
    intl: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(injectIntl(NetworkMissortSuccessScreen));