import {fetchProblemCategoryConfig} from "../handler/ProblemCategoryConfigHandler";
// import {getShipmentsByScannableId} from "../network/apis/npsw/GetShipmentsByScannableIdAPI";
import {getShipmentsByScannableIds} from "../network/apis/nns/GetShipmentsByScannableIdsAPI";
import {NOTIFICATION_TYPE} from "../constants/Constants";

export const LeftToFixShipmentDetailInitialState = {
    shipments: [],
    problemCategoryConfig:{},
    loadNextComponentSucess: false,
    loading: false,
    isError: false,
    notificationType: null,
    notificationMessageId: "",
    scannableId: null
}

export const LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE = {
    FETCH_SHIPMENT_DETAILS: "FETCH_SHIPMENT_DETAILS",
    SET_SHIPMENT_DETAILS: "SET_SHIPMENT_DETAILS",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    SHOW_INVALID_CATEGORY_NOTIFICATION:"SHOW_INVALID_CATEGORY_NOTIFICATION",
    ERROR: "ERROR"
}

export const LeftToFixShipmentDetailReducer = (state, action) => {
    switch (action.type) {
        case LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.FETCH_SHIPMENT_DETAILS: {
            getShipmentsByScannableIds({
                scannableIds: action.data
            }, action.dispatcher, LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.SET_SHIPMENT_DETAILS, LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.ERROR)
            return {...state, loading: true, isError: false}
        }

        case LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.SET_SHIPMENT_DETAILS: {
            return {...state, scannableId: action.data.shipments[0].scannableId, shipments: action.data.shipments,
                loading: false, loadNextComponentSucess: true, problemCategoryConfig: fetchProblemCategoryConfig()}
        }

        case LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.ERROR: {
            return {...state, loading: false, notificationType: NOTIFICATION_TYPE.ALERT, notificationMessageId:"package_not_found"};
        }

        case LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.CLOSE_NOTIFICATION: {
            return {...state, notificationType: null};
        }

        case LEFT_TO_FIX_SHIPMENT_DETAIL_REDUCER_ACTION_TYPE.SHOW_INVALID_CATEGORY_NOTIFICATION: {
            return {...state, shipmentDetailFetched: false, notificationType: NOTIFICATION_TYPE.WARNING,
                notificationMessageId: "category_solve_not_found", loadNextComponentSucess: false};
        }

        default:
            return state
    }
}