import React from 'react';
import {HelpView} from "@amzn/dolphin-uiweb-framework";
import HELP_ICON from "../../assets/images/ic_help_coming_up.png";
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import {withRouter} from 'react-router-dom';
import {getDisplayName} from "../../handler/TranslationHandler";

const HelpPage = ({history, intl}) => {

  return (<HelpView onClose={() => history.goBack()}
                    title={getDisplayName(intl, "help_center")}
                    help_icon={HELP_ICON}/>)
};

HelpPage.propTypes = {
  history: PropTypes.object,
  intl: PropTypes.object,
};
export default withRouter(injectIntl(HelpPage));