import React, {useEffect, useReducer} from "react";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {getDisplayName} from "../../handler/TranslationHandler";
import DriverEntityCommon from "./DriverEntityCommon";
import {DRIVER_ENTITY_ROUTE_PATH} from "../../router/DriverEntityRouter";
import InProgress from "../common/InProgress";
import {
    DRIVER_ENTITY_REDUCER_ACTION_TYPE,
    DriverEntityReducer,
    DriverEntityReducerInitialState
} from "../../reducers/DriverEntityReducer";
import NotificationMessage from "../common/NotificationMessage";
import {
    saveProblemCategory
} from "../../dataStore/PSExecutionDataStore";
import {
    getDriverActionTransporter,
} from "../../dataStore/LocationStateDataStore";
import PropTypes from 'prop-types';
import ToastMessage from "../common/ToastMessage";
import { FeatureManager } from "@amzn/dolphin-web-framework";

const TRANSPORTER_OVERRIDE = "TRANSPORTER_OVERRIDE";
const TRANSPORTER_NO_SHOW = "TRANSPORTER_NO_SHOW";

const DriverActions = ({intl, history, location}) => {

    if(!location.state){
        location.state = {
            transporter: getDriverActionTransporter()
        }
    }

    const transporter = location.state.transporter
    const [{loading, notificationType, notificationMessageId, driverProblemCategories}, dispatch] =
        useReducer(DriverEntityReducer, DriverEntityReducerInitialState)

    useEffect(() => {
        dispatch({
            type: DRIVER_ENTITY_REDUCER_ACTION_TYPE.GET_TRANSPORTER_PROBLEMS,
            dispatcher: dispatch
        })
    }, []);

    const onClickDriverAction = (problemCategory) => {
        if(TRANSPORTER_OVERRIDE === problemCategory){
            saveProblemCategory(problemCategory)
            history.push({
                pathname: DRIVER_ENTITY_ROUTE_PATH.DRIVER_OVERRIDE_REASON,
                state: {
                    transporter: transporter
                }
            });
        } else if(TRANSPORTER_NO_SHOW === problemCategory){
            history.push({
                pathname: DRIVER_ENTITY_ROUTE_PATH.DRIVER_NO_SHOW,
                state: {
                    transporter: transporter
                }
            });
        } else {
            if (FeatureManager.isFeatureEnabled(FeatureManager.Features.DRIVER_OVERBOOKING_ENABLED)) {
                history.push({
                    pathname: DRIVER_ENTITY_ROUTE_PATH.DRIVER_OVERBOOK_CONFIRM,
                    state: {
                        transporterId: transporter.transporterId,
                        problemCategory: problemCategory,
                        transporterName: transporter.transporterName
                        
                    }
                })
            } else {
                dispatch({
                    type: DRIVER_ENTITY_REDUCER_ACTION_TYPE.CALL_UPDATE_EXCEPTION_TRASPORTER,
                    dispatcher: dispatch,
                    data: {
                        transporterId: transporter.transporterId,
                        problemCategory: problemCategory,
                        transporterName: transporter.transporterName
                    }
                })
            }
            
        }
    }

    let transporterProblemCategories = driverProblemCategories

    const getDriverProblemCategory = () => {
        return transporterProblemCategories.map((problemCategory) => {
            return {
                title: getDisplayName(intl, problemCategory),
                description: getDisplayName(intl, problemCategory + "_desc"),
                onClick: ()=>onClickDriverAction(problemCategory)
            }
        })
    }
    return (
        <Box>
            {loading && <InProgress/>}
            {notificationType &&
            <NotificationMessage dispatch={dispatch} actionType={DRIVER_ENTITY_REDUCER_ACTION_TYPE.CLOSE_NOTIFICATION}
                                 type={notificationType} messageId={notificationMessageId}/>}
            <DriverEntityCommon transporter={transporter} />
            <Box spacingInset="none medium small medium">
                <Text type="h300"><FormattedMessage id={"whats_the_problem"}/></Text>
            </Box>
            {(transporterProblemCategories.length === 0)&&
            <ToastMessage text="no_actions_available"/>}
            <TaskView tasks={getDriverProblemCategory()}/>
        </Box>
    )
}

DriverActions.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    intl: PropTypes.object
};

export default DriverActions