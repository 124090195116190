import {getPlanForPackage} from "../network/apis/sps/GetPlanForPackage";
import {NOTIFICATION_TYPE} from "../constants/Constants";

export const GSFPackageInitialState = {
    loading: false,
    notificationType: "",
    notificationMessageId: ""
};
export const GSF_PACKAGE_REDUCERS_ACTION_TYPE = {
    FETCH_ROUTE_CODE: "FETCH_ROUTE_CODE",
    SET_ROUTE_CODE: "SET_ROUTE_CODE",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    ERROR: "ERROR"
};

export const GSFPackageReducer = (state, action) => {
    switch (action.type) {
        case GSF_PACKAGE_REDUCERS_ACTION_TYPE.FETCH_ROUTE_CODE: {
            getPlanForPackage(action.data, action.dispatcher, GSF_PACKAGE_REDUCERS_ACTION_TYPE.SET_ROUTE_CODE,
                GSF_PACKAGE_REDUCERS_ACTION_TYPE.ERROR);
            return {...state, loading: true};
        }
        case GSF_PACKAGE_REDUCERS_ACTION_TYPE.SET_ROUTE_CODE: {
            return {...state, loading: false, routeCode: action.data.executionPlan.routeCode};
        }
        case GSF_PACKAGE_REDUCERS_ACTION_TYPE.ERROR: {
            return {...state, loading: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"internal_error"};
        }
        case GSF_PACKAGE_REDUCERS_ACTION_TYPE.CLOSE_NOTIFICATION:
            return {...state, notificationType: null};
        default:
            return state;
    }
};