import getShipmentByScannableIdResponse from "../__mocks__/GetShipmentByScannableIdResponse.json";
import getStations from "../__mocks__/GetStations";

export const NNSClient = {
    getShipmentsByScannableIdsV2: (args) => {
        console.log("NNSClient getShipmentsByScannableIdsV2 "+ args);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getShipmentByScannableIdResponse})
            }, 500)
        })
    },
    getStationList: async () => {
        console.log("Request: getStationList");
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getStations })
            },1000)
        })
    }

};