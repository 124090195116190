import React, {createContext, useReducer} from "react";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";

export const AppContext = createContext();

export const actionType = {
    //App Actions for Back Press View
    OPEN_BACK_DROP: "OPEN_BACK_DROP",
    CLOSE_BACK_DROP: "CLOSE_BACK_DROP",
    SET_BACK_PRESS: "SET_BACK_PRESS",
    SET_PRIMARY_ONCLICK: "SET_PRIMARY_ONCLICK",
}

export const BackOption = {
    CLOSE: "CLOSE",
    EXIT: "EXIT",
    CONTINUE: "CONTINUE"
}

export const ApplicationActions = {
    BACK_SELECTED: "BACK_SELECTED",
    BACK_OPTION_SELECTED: "BACK_OPTION_SELECTED",
}

const AppContextProvider = (props) => {
    const initState = {
        onBackPressed: null,
        onPrimaryOnClick: null,
        backOptions: {
            open: false,
            primaryButtonOnClick:  () => {
                appActions.closeBackDrop()
                state.onPrimaryOnClick && state.onPrimaryOnClick();
            },
            secondaryButtonOnClick: () => {
                appActions.closeBackDrop();
                NativeMeshInteractor.exitModule();
            }
        }
    };

    const [state, dispatch]= useReducer(AppReducer, initState);

    const appActions = {
        openBackDrop: () => {
            dispatch({type: actionType.OPEN_BACK_DROP});
        },
        closeBackDrop: () => {
            dispatch({type: actionType.CLOSE_BACK_DROP});
        },
        setBackPress: (option) => {
            dispatch({type: actionType.SET_BACK_PRESS, data: option});
        },
        resetBackPress: () => {
            dispatch({type: actionType.SET_BACK_PRESS, data: null});
        },
        setPrimaryOnClick: (option) => {
            dispatch({type: actionType.SET_PRIMARY_ONCLICK, data: option});
        },
        resetPrimaryOnClick: () => {
            dispatch({type: actionType.SET_PRIMARY_ONCLICK, data: null});
        },
        resetAppContext: () => {
            dispatch({type: actionType.RESET_STATE})
        }
    }

    return (
        <AppContext.Provider value={{state, appActions}}>
            {props.children}
        </AppContext.Provider>
    )
}

export const AppReducer = (state, action) => {
    switch (action.type) {
        /**
         * Backdrop options
         */
        case actionType.OPEN_BACK_DROP:
            return {...state, backOptions: {...state.backOptions, open: true}};

        case actionType.CLOSE_BACK_DROP:
            return {...state, backOptions: {...state.backOptions, open: false}}

        case actionType.SET_BACK_PRESS:
            return {...state, onBackPressed: action.data}

        case actionType.SET_PRIMARY_ONCLICK:
            return {...state, onPrimaryOnClick: action.data}

        case actionType.RESET_STATE:
            return {
                ...state,
                onBackPressed: null,
                onPrimaryOnClick: null,
            }

        default:
            return state
    }
}

export default AppContextProvider;
