import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import "@amzn/meridian-tokens/base/font/amazon-ember.css";
import {IntlProvider} from "react-intl";
import App from "./components/app";
import {getDirection, getLocale, getMessages} from "./handler/LocaleHandler";
import ErrorBoundary from "./components/ErrorBoundary";
import "@amzn/dolphin-uiweb-framework/dist/index.css";
import {
    DataHandler,
    FeatureManager,
    Logger,
    MobileAnalyticsHelper,
    PUMAMetricHelper
} from "@amzn/dolphin-web-framework";
import {
    getAnalyticsRegion,
    getLoggerUrl,
    getPUMACountry,
    getPUMARegion,
    getPUMAStage,
    getStage
} from "./config/NetConf";
import {PUMA_APP_ID, PUMA_MODULE_NAME} from "./config/PumaConfig";
import {SoundType} from "./audio/SoundManager";

function playSilentSound() {
    // do things, then
    SoundType.SILENT.play();
    document.removeEventListener('click', playSilentSound);
}

/**
 * Playing a silent sound on first click to capture the user gesture.
 */
document.addEventListener('click', playSilentSound);
DataHandler.parseSearchParams();
Logger.setLogger(getLoggerUrl(), getStage());
PUMAMetricHelper.pumaInit(getPUMACountry(), getPUMARegion(), getPUMAStage(), PUMA_APP_ID, PUMA_MODULE_NAME);
MobileAnalyticsHelper.initializeAnalytics(getAnalyticsRegion());
document.getElementsByTagName(`html`)[0].setAttribute("dir", getDirection());

if(FeatureManager.isFeatureEnabled(FeatureManager.Features.HASH_ROUTER_ENABLED)) {
    Logger.log.info("HashRouter is enabled for station:", DataHandler.getStationCode());
    ReactDOM.render((
        <IntlProvider locale={getLocale()} messages={getMessages()}>
            <HashRouter>
                <ErrorBoundary>
                    <App/>
                </ErrorBoundary>
            </HashRouter>
        </IntlProvider>
    ), document.getElementById('root'))
}
else {
    Logger.log.info("BrowserRouter is enabled for station:", DataHandler.getStationCode());
    ReactDOM.render((
        <IntlProvider locale={getLocale()} messages={getMessages()}>
            <BrowserRouter>
                <ErrorBoundary>
                    <App/>
                </ErrorBoundary>
            </BrowserRouter>
        </IntlProvider>
    ), document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: (serviceWorkerRegistration) => {
        const registrationWaiting = serviceWorkerRegistration.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({type: 'SKIP_WAITING'});
            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    }
});

