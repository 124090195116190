import {getCategoriesForNodeIdAPI} from "../network/apis/npsw/GetCategoriesForNodeIdAPI";
import {getFeatureValue} from "../network/apis/npsw/GetFeatureValueAPI";

import {fetchExceptionShipmentSummaryCount, fetchQueueToCount, fetchShipmentSummaryDetails,
    fetchOpenShipmentDetails, fetchLeftToFixFilters} from "../helper/Left2FixHelper"
import {FEATURE_KEY} from "../constants/Constants"
import {LeftToFixFiterKey} from "../constants/Left2FixFilterKey";
import {fetchExceptionShipmentSummaryWithoutShipDetails, fetchScrubFilters} from "../helper/ScrubHelper";

export const LeftToFixReducerInitialState = {
    loading: false,
    fetchingShipmentDetails: false,
    queueToCategories:{},
    exceptionQueueToCount:[],
    selectedQueueDetails:{},
    exceptionSizeThreshold: FEATURE_KEY.EXCEPTION_SIZE_THRESHOLD_DEFAULT_VALUE,
    exceptionShipmentSummaryReport:{},
    openExceptionShipmentsWithDetails:[],
    showLeftToFixFilter:false,
    leftToFixFilters:[],
    exceptionCategoriesToCount:[],
    scrubExceptionCount: []
};

export const LEFT_TO_FIX_REDUCERS_ACTION_TYPE = {
    FETCH_CATEGORIES_FOR_NODE_ID: "FETCH_CATEGORIES_FOR_NODE_ID",
    FETCH_EXCEPTION_SHIPMENT_COUNT: "FETCH_EXCEPTION_SHIPMENT_COUNT",
    FETCH_SCRUB_COUNT: "FETCH_SCRUB_COUNT",
    SET_EXCEPTION_CATEGORIES_TO_COUNT: "SET_EXCEPTION_CATEGORIES_TO_COUNT",
    FETCH_EXCEPTION_SIZE_THRESHOLD: "FETCH_EXCEPTION_SIZE_THRESHOLD",
    FETCH_EXCEPTION_SHIPMENT_SUMMARY_DETAILS: "FETCH_EXCEPTION_SHIPMENT_SUMMARY_DETAILS",
    SET_EXCEPTION_SHIPMENT_SUMMARY_DETAILS: "SET_EXCEPTION_SHIPMENT_SUMMARY_DETAILS",
    ERROR: "ERROR"
}

export const LeftToFixReducer = (state, action) => {
    switch (action.type) {
        case LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_CATEGORIES_FOR_NODE_ID:
            getCategoriesForNodeIdAPI(action.data, action.dispatcher, LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_SCRUB_COUNT,
                LEFT_TO_FIX_REDUCERS_ACTION_TYPE.ERROR);
            return {...state, loading: true};

        case LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_SCRUB_COUNT:
            fetchExceptionShipmentSummaryWithoutShipDetails(action.dispatcher)
            return {...state, queueToCategories: action.data, loading:true};

        case LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_EXCEPTION_SHIPMENT_COUNT: {
            const scrubCount = fetchScrubFilters(action.data)
            fetchExceptionShipmentSummaryCount(state.queueToCategories, action.dispatcher)
            return {...state, scrubExceptionCount: scrubCount, loading: true};
        }

        case LEFT_TO_FIX_REDUCERS_ACTION_TYPE.SET_EXCEPTION_CATEGORIES_TO_COUNT: {
            const exceptionQueueToCount = fetchQueueToCount(action.data, state.queueToCategories)
            return {...state, exceptionQueueToCount,exceptionShipmentSummaryReport: action.data.exceptionShipmentSummaryReport,
                loading: false};
        }

        case  LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_EXCEPTION_SIZE_THRESHOLD: {
            getFeatureValue({featureKey: FEATURE_KEY.EXCEPTION_SIZE_THRESHOLD}, action.dispatcher,
                LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_EXCEPTION_SHIPMENT_SUMMARY_DETAILS, LEFT_TO_FIX_REDUCERS_ACTION_TYPE.ERROR)
            return {...state, selectedQueueDetails: action.data, fetchingShipmentDetails: true};
        }

        case  LEFT_TO_FIX_REDUCERS_ACTION_TYPE.FETCH_EXCEPTION_SHIPMENT_SUMMARY_DETAILS: {
            let exceptionSizeThreshold = action.data === 0 ? FEATURE_KEY.EXCEPTION_SIZE_THRESHOLD_DEFAULT_VALUE : action.data
            if(exceptionSizeThreshold < state.selectedQueueDetails.exceptionCount) {
                return {...state,
                    exceptionSizeThreshold,
                    leftToFixFilters:[LeftToFixFiterKey.ISSUE_TYPE],
                    showLeftToFixFilter: true,
                    fetchingShipmentDetails: false
                };
            } else {
                fetchShipmentSummaryDetails(state.selectedQueueDetails, state.queueToCategories, action.dispatcher)
                return {...state, exceptionSizeThreshold, fetchingShipmentDetails: true};
            }
        }

        case LEFT_TO_FIX_REDUCERS_ACTION_TYPE.SET_EXCEPTION_SHIPMENT_SUMMARY_DETAILS: {
            let openExceptionShipmentsWithDetails = fetchOpenShipmentDetails(action.data.exceptionShipmentSummaryReport)
            return {
                ...state,
                openExceptionShipmentsWithDetails,
                leftToFixFilters: fetchLeftToFixFilters(openExceptionShipmentsWithDetails),
                showLeftToFixFilter: true,
                fetchingShipmentDetails: false
            };
        }

        case LEFT_TO_FIX_REDUCERS_ACTION_TYPE.ERROR:
            return {...state, loading: false, fetchingShipmentDetails: false};
        default:
            return state
    }
};