import React from "react";
import {Route, Switch} from "react-router-dom";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl";
import DriverActions from "../components/driverEntity/DriverActions";
import TopNavigation from "../components/common/TopNavigation";
import DriverOverrideReason from "../components/driverEntity/DriverOverrideReason";
import Column from "@amzn/meridian/column";
import DriverNoShow from "../components/driverEntity/DriverNoShow";
import DriverNoShowResultScreen from "../components/driverEntity/DriverNoShowResultScreen";
import DriverOverbookConfirm from "../components/driverEntity/DriverOverbookConfirm"
import AppContextProvider from "../components/backPressedView/AppContext";

export const DRIVER_ENTITY_ROUTE_PATH = {
    DRIVER_ACTION: "/driverEntity/actions",
    DRIVER_OVERRIDE_REASON: "/driverEntity/overrideReason",
    DRIVER_NO_SHOW: "/driverEntity/daNoShow",
    DRIVER_NO_SHOW_RESULT_SCREEN: "/driverEntity/daNoShowResultScreen",
    DRIVER_OVERBOOK_CONFIRM: "/driverEntity/daOverbook"
};

export default function DriverEntityRouter() {

    return (
        <div>
            <AppContextProvider>
                <TopNavigation title="operation_problem_solve"/>
            </AppContextProvider>
            <div className="content">
                <Column height="90vh">
                    <Switch>
                        <Route path={DRIVER_ENTITY_ROUTE_PATH.DRIVER_ACTION} component={withRouter(injectIntl(DriverActions))}/>
                        <Route path={DRIVER_ENTITY_ROUTE_PATH.DRIVER_OVERRIDE_REASON}
                               component={withRouter(injectIntl(DriverOverrideReason))}/>
                        <Route path={DRIVER_ENTITY_ROUTE_PATH.DRIVER_NO_SHOW}
                               component={withRouter(injectIntl(DriverNoShow))}/>
                        <Route path={DRIVER_ENTITY_ROUTE_PATH.DRIVER_NO_SHOW_RESULT_SCREEN}
                               component={withRouter(injectIntl(DriverNoShowResultScreen))}/>
                        <Route path={DRIVER_ENTITY_ROUTE_PATH.DRIVER_OVERBOOK_CONFIRM}
                        component={withRouter(injectIntl(DriverOverbookConfirm))}/>
                    </Switch>
                </Column>
            </div>
        </div>
    )

}