import {UpdateExceptionShipmentsAPI} from "../network/apis/npsw/UpdateExceptionShipmentsAPI";
import {
    getProblemActionNextComponentDetails,
    getProblemCategory,
    getScannedScannableIds, saveExceptionResolutionResult
} from "../dataStore/PSExecutionDataStore";
import {processApiExecutionResponse} from "../helper/ActionComponentsHelper";
import {NOTIFICATION_TYPE, TARGET_PROBLEM_STATUS} from "../constants/Constants";

const ACTION = "STAGE_ITEM"

export const StageItemInitialState = {
    loading: false,
    loadNextComponent: false,
    nextComponentDetails:{},
    exceptionResolutionResult: {},
    notificationMessageId: ""
}

export const STAGE_ITEM_REDUCERS_ACTION_TYPE = {
    EXECUTE_PROBLEM_ACTION_API: "EXECUTE_PROBLEM_ACTION_API",
    SET_PROBLEM_ACTION_API_RESULT: "SET_PROBLEM_ACTION_API_RESULT",
    INCORRECT_STAGE_LOCATION_SCANNED: "INCORRECT_STAGE_LOCATION_SCANNED",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    ERROR: "ERROR"
};

export const StageItemReducer = (state, action) => {
    switch (action.type) {
        case STAGE_ITEM_REDUCERS_ACTION_TYPE.EXECUTE_PROBLEM_ACTION_API: {
            UpdateExceptionShipmentsAPI({
                    scannableIdList: getScannedScannableIds(),
                    problemCategory: getProblemCategory(),
                    targetProblemStatus: TARGET_PROBLEM_STATUS.RESOLVED,
                    targetAction: ACTION
                },
                {
                    StagingArea: action.data.stageArea,
                    picklistId: action.data.pickListId,
                    EntityType: action.data.entityType,
                    containerTaskId: action.data.containerTaskId
                },
                STAGE_ITEM_REDUCERS_ACTION_TYPE.SET_PROBLEM_ACTION_API_RESULT,
                STAGE_ITEM_REDUCERS_ACTION_TYPE.ERROR,
                action.dispatcher);
            return {
                ...state,
                loadNextComponent: false,
                loading: true,
                nextComponentDetails: getProblemActionNextComponentDetails()
            }
        }
        case STAGE_ITEM_REDUCERS_ACTION_TYPE.SET_PROBLEM_ACTION_API_RESULT: {
            const {exceptionResolutionResult, loadNextComponent} = processApiExecutionResponse(action.data)
            saveExceptionResolutionResult(exceptionResolutionResult)
            return {
                ...state,
                loadNextComponent: loadNextComponent,
                loading: false,
                exceptionResolutionResult: exceptionResolutionResult
            };
        }

        case STAGE_ITEM_REDUCERS_ACTION_TYPE.INCORRECT_STAGE_LOCATION_SCANNED: {
            return {
                ...state,
                loadNextComponent: false,
                loading: false,
                notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"error_stage_area_location_mismatch",
                exceptionResolutionResult:{}
            };
        }

        case STAGE_ITEM_REDUCERS_ACTION_TYPE.ERROR: {
            return {
                ...state,
                loadNextComponent: false,
                loading: false,
                notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"internal_error",
                exceptionResolutionResult:{}
            };
        }

        case STAGE_ITEM_REDUCERS_ACTION_TYPE.CLOSE_NOTIFICATION: {
            return {...state, notificationType: null};
        }

        default:
            return state;
    }
}