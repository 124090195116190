import {callAPI} from "../network/base/NetworkInterface";
import {HTTP_METHOD} from "../constants/Constants";
import {Logger} from "@amzn/dolphin-web-framework";

const NodeNinjaServiceEndpoint = "/nns/";

const ENDPOINTS = {
    getShipmentsByScannableIdsV2: "getShipmentsByScannableIds",
    getStationList: "getStationList"
}
export const NNSClient = {
    getShipmentsByScannableIdsV2: async (args) => {
        Logger.log.info("Request: getShipmentsByScannableIdsV2, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.getShipmentsByScannableIdsV2),
            data: args
        });
    },
    getStationList: async () => {
        Logger.log.info("Request: getStationList");
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.getStationList),
        })
    }
}

const getEndPoint = (serviceEndpoint) => {
    return NodeNinjaServiceEndpoint + serviceEndpoint
}