import {HTTP_METHOD} from "../../constants/Constants";
import {getProblemSolveServiceBaseURL} from "../../config/NetConf";
import {DolphinAPIClientProvider, Logger, PUMAMetricHelper} from "@amzn/dolphin-web-framework";

export function getProblemSolveClient() {
  DolphinAPIClientProvider.generateDolphinAPIClient(getProblemSolveServiceBaseURL());
  return DolphinAPIClientProvider.dolphinAPIClient;
}

export const callAPI = async ({method, endpoint, data}) => {
  let startTime = Date.now();
  PUMAMetricHelper.publishCountToDolphinCSM(endpoint);
  switch (method) {
    case HTTP_METHOD.GET:
    return await axiosGET(endpoint, data)
    .then((response) => {
      PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, endpoint);
      console.log("CallAPI GET response : " + JSON.stringify(response.data));
      return {success: true, data: response.data};
    })
    .catch((error) => {
      Logger.log.error(error)
      publishErrorMetric(endpoint, startTime, error);
      return {success: false, data: error.data};
    });

    case HTTP_METHOD.POST:
    return await axiosPOST(endpoint, data)
      .then((response) => {
        PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, endpoint);
        return {success: true, data: response.data};
      })
      .catch((error) => {
        Logger.log.error(error);
        publishErrorMetric(endpoint, startTime, error);
        return {success: false, data: error.data};
      });
    default:
    return {success: false};
  }
};

export async function axiosPOST(path, requestData) {
return getProblemSolveClient().post(path, requestData);
}

export async function axiosGET(path, data) {
  return getProblemSolveClient().get(path, {params: data});
}

function publishErrorMetric(metricName, startTime, error) {
//Emitting Failure Metrics
PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
PUMAMetricHelper.publishCountToDolphinCSM(metricName + "-failure");
if (error.status >= 400 && error.status < 500) {
PUMAMetricHelper.publishCountToDolphinCSM("4xxError");
} else {
PUMAMetricHelper.publishCountToDolphinCSM("5xxError");
}
}
